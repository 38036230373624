import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {SearchedArticle} from "../../../../../../models/articles/article";
import {articlesService} from "../../../../../../services/articles/articles.service";
import {Dropdown, Input} from "antd";
import {SearchValue} from "../../../../catalog/catalog.functions";
import {CloseCircleFilled} from '@ant-design/icons';

function getLabel(article: SearchedArticle) {
    return article.brand + ' - ' + article.sku + (article.title ? (' - ' + article.title) : '');
}

function getValue(article: SearchedArticle, queryString: string): SearchValue {
    return [(article.analogue ? 'a' : 'f'), article.vid, article.sku, queryString];
}

function strictQueryStringValue(queryString: string): SearchValue {
    return ['q', 0, '', queryString];
}

interface UIInputWidgetSearchProps {
    value?: SearchValue;
    onChange?: (value?: SearchValue) => void;
}

export const UIInputWidgetSearch: React.FC<UIInputWidgetSearchProps> = ({value, onChange}) => {

    const {t} = useTranslation('translation', {useSuspense: false});

    const [options, setOptions] = React.useState<{value: SearchValue, label: string}[]>([]);
    const [optionsShown, setOptionsShown] = React.useState<boolean>(false);
    const [optionsLoaded, setOptionsLoaded] = React.useState<boolean>(false);

    const [inputValue, setInputValue] = React.useState<string>("");

    const loadOptions = React.useMemo(() => {
        let timer: NodeJS.Timeout | undefined;
        const fn: { (value: string, showOptionsImmediately?: boolean): void; cancelTimer(): void; } = (value: string, showOptionsImmediately: boolean = true) => {
            if (timer) {
                clearTimeout(timer)
            }
            if (value.length <= 5) {
                return
            }
            timer = setTimeout(() => {
                onValueUpdated(value, showOptionsImmediately);
            }, 1000);
        }
        fn.cancelTimer = () => {
            if (timer) {
                clearTimeout(timer)
            }
        };

        return fn;

    }, []);

    const onValueUpdated = async (value: string, showOptionsImmediately: boolean = true) => {
        if (value.length <= 5) {
            return
        }
        const result: SearchedArticle[] = await articlesService.shortSearch(value);
        const manualOption = {value: strictQueryStringValue(value), label: t('SEARCH_BY') + ': ' + value};
        setOptionsLoaded(true);
        if (result.length > 0) {
            setOptions([...result.map(v => ({value: getValue(v, value), label: getLabel(v)})), manualOption]);
        } else {
            setOptions([manualOption])
        }
        if (showOptionsImmediately) {
            setOptionsShown(true)
        }
    }

    const onInputValueChanged = (inputValue: string, showOptionsImmediately: boolean = true, withLoadOptions: boolean = false) => {
        setOptionsShown(false)
        setInputValue(oldInputValue => {
            if (withLoadOptions && oldInputValue !== inputValue) {
                loadOptions(inputValue, showOptionsImmediately);
            }
            return inputValue;
        })
    }

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setTimeout(() => {
            setOptionsShown(false)
        }, 200);
    }

    const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        if (!optionsLoaded) {
            onValueUpdated(e.target.value, true);
        }
        if (options.length > 0) {
            setOptionsShown(true)
        }
    }

    const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const clipboardValue = e.clipboardData.getData('text');
        if (clipboardValue) {
            setInputValue(clipboardValue)
            loadOptions(clipboardValue, true)
            e.preventDefault();
        }
    }

    const onValueChange = (value: SearchValue) => {
        onChange && onChange(value)
    }

    const onClear = () => {
        onChange && onChange(undefined)
    }

    const onEnter = () => {
        loadOptions.cancelTimer();
        onValueChange(strictQueryStringValue(inputValue))
    }

    useEffect(() => {
        if (value) {
            const [t, i, s, v] = value;
            onInputValueChanged(v, false, false);
        }
    }, [value]);

    return (
        <>
            <Dropdown
                open={optionsShown}
                menu={{
                    items: options.map(o => ({
                        label: o.label,
                        key: o.value.join(':'),
                        value: o.value,
                        onClick: () => onValueChange(o.value)
                    }))
                }}
            >
                <Input
                    bordered={false}
                    value={inputValue}
                    onChange={e => onInputValueChanged(e.target.value, true, true)}
                    allowClear={{
                        clearIcon: <CloseCircleFilled onClick={onClear} />
                    }}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onPaste={onPaste}
                    onPressEnter={onEnter}
                    placeholder={t("CATALOG.SEARCH.TITLE")}
                />
            </Dropdown>
        </>
    )
}